import { createTheme, ThemeProvider } from '@mui/material/styles'

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            primary: string;
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            primary?: string;
        };
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#eee',
            contrastText: '#eee'
        },
        secondary: {
            main: '#edf2ff',
        },
        background: {
            paper: '#222',
            default: '#222'
        }
    }
});

export function ThemeProv({ children }: { children: React.ReactNode }) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}