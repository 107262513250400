
import { Box, IconButton, Slide, useTheme } from "@mui/material"

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import DotsMobileStepper from '../DotsMobileStepper';
import { CardType, CardLayout, CardIconButton, Card } from './Card';
import { useSearchParams } from "react-router-dom";

const CARD_TRANSITION_TIME = 400

export function CardGroup({ cards }: {
    cards: CardType[]
}) {
    let [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme()

    const [transitionStep, setTransitionStep] = useState(0);
    const [activeStep, setActiveStep] = useState(0);

    function handleChangeStep(step: number) {
        // console.log("handleChangeStep", step)
        setShowRight(step > transitionStep)

        setTransitionStep(step)
        setTimeout(() => setActiveStep(step), CARD_TRANSITION_TIME)

        const card = cards[step]
        if (!card)
            return

        if (!card.title)
            return

        searchParams.set("topic", card.title)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        var topic = searchParams.get("topic")

        var t = 0
        for (let i = 0; i < cards.length; i++) {
            const title = cards[i].title;
            if (title === topic)
                t = i
        }
        if (t === 0) {
            searchParams.set("topic", cards[t]?.title || "")
            setSearchParams(searchParams)
        }

        setActiveStep(t)
        setTransitionStep(t)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cards])

    const onClickNext = () => handleChangeStep(transitionStep + 1)
    const onClickPrev = () => handleChangeStep(transitionStep - 1)

    const [showRight, setShowRight] = useState(false)

    if (cards.length === 0 || !(cards[activeStep]) || !(cards[transitionStep]))
        return <div></div>
    return (
        <Fragment>
            <CardLayout
                sx={{
                    position: "relative",
                    overflow: "hidden"
                }}
            >
                {/* {transitionStep + " " + activeStep} */}
                {/* <Card {...cards[activeStep]} /> */}

                <Slide direction={showRight ? "right" : "left"} appear={false} in={transitionStep === activeStep} timeout={{ enter: 0, exit: CARD_TRANSITION_TIME }} easing={theme.transitions.easing.easeInOut}>
                    <Box>
                        {/* <CardLayout sx={{ backgroundColor: "red" }}>Righta<br />"right"</CardLayout> */}
                        <Card  {...cards[transitionStep === activeStep ? transitionStep : activeStep]} />
                    </Box>
                </Slide>

                {
                    transitionStep < activeStep &&
                    <Slide direction="right" in={true} timeout={CARD_TRANSITION_TIME} easing={theme.transitions.easing.easeInOut}>
                        <Box>
                            {/* <CardLayout sx={{ backgroundColor: "green" }}>Left</CardLayout> */}
                            <Card  {...cards[transitionStep]} />
                        </Box>
                    </Slide>
                }
                {
                    transitionStep > activeStep &&
                    <Slide direction="left" in={true} timeout={CARD_TRANSITION_TIME} easing={theme.transitions.easing.easeInOut}>
                        <Box>
                            {/* <CardLayout sx={{ backgroundColor: "yellow" }}>Left</CardLayout> */}
                            <Card  {...cards[transitionStep]} />
                        </Box>
                    </Slide>
                }

                <CardIconButton
                    aria-label="prev"
                    className={clsx("card-button left", { "active": transitionStep !== 0 })}

                    onClick={onClickPrev}
                    disabled={transitionStep === 0}>
                    <KeyboardArrowLeft fontSize="inherit" color='inherit' />
                </CardIconButton>
                <CardIconButton
                    aria-label="next"
                    className={clsx("card-button right", { "active": transitionStep !== cards.length - 1 })}
                    sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        marginTop: "auto",
                        marginBottom: "auto",
                        height: "40px",
                        width: "40px",
                    }}
                    onClick={onClickNext}
                    disabled={transitionStep === cards.length - 1}
                >
                    <KeyboardArrowRight fontSize="inherit" color='inherit' />
                </CardIconButton>
            </CardLayout >
            {
                cards.length > 1 && (
                    <DotsMobileStepper
                        titles={cards.map(c => c.title)}
                        steps={cards.length}
                        activeStep={transitionStep}
                        onClick={s => handleChangeStep(s)}
                    />
                )
            }
        </Fragment>
    )
}