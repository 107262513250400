import React, { useState } from 'react'
import { Theme } from '@emotion/react';
import { Box, ExtendButtonBase, IconButton, IconButtonTypeMap, SxProps } from "@mui/material"
import { ComponentProps, Fragment, PropsWithChildren } from 'react';
import NotesIcon from '@mui/icons-material/Notes';
import PanoramaIcon from '@mui/icons-material/Panorama';

export type CardType = {
    title?: string,
    subTitle?: string,
    subTitle2?: string,
    subTitleAfterTitle?: string,
    content: React.ReactNode,
    color?: string,
    backgroundImage?: string,
    backgroundVideoYoutubeCode?: string,
    footer?: React.ReactNode
}

export function CardIconButton(props: ComponentProps<typeof IconButton>) {
    return (
        <IconButton
            {...props}
            onClick={props.onClick}
            sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                marginTop: "auto",
                marginBottom: "auto",
                height: "40px",
                width: "40px",
                ...props.sx
            }}>
            {props.children}
        </IconButton >
    )
}

export function createCard(
    {
        color,
        title,
        content,
        footer,
        backgroundImage,
        backgroundVideoYoutubeCode,
        subTitle,
        subTitle2,
        subTitleAfterTitle
    }: CardType) {
    const card: CardType = {
        title,
        backgroundImage,
        backgroundVideoYoutubeCode,
        color,
        content: (
            <Box mx={2} sx={{ display: "flex", flexDirection: "column", wordWrap: "break-word" }} width={{ xs: "70%", sm: "50%" }} height={"100%"}>
                <Box mt={2}>
                    <h2 style={{ display: "inline" }}>{title}</h2> <h3 style={{ display: "inline-block", margin: "0 0 0 0" }}>{subTitleAfterTitle}</h3>
                    {subTitle && <h3 style={{ marginTop: 5, marginBottom: 0 }}>{subTitle}</h3>}
                    {subTitle2 && <h4 style={{ marginTop: 5, marginBottom: 0 }}>{subTitle2}</h4>}
                </Box>
                <Box>
                    {content}
                </Box>
                {footer &&
                    <Box pb={2} marginTop="auto">
                        {footer}
                    </Box>
                }
            </Box>
        )
    }
    return card
}

export function CardLayout({ children, sx, style }: {
    children: React.ReactNode,
    sx?: SxProps<Theme> | undefined,
    style?: React.CSSProperties | undefined
}) {
    return (
        <Box
            width={{ xs: "100%", sm: "600px", md: "800px", lg: "1000px" }}
            height={{ xs: "70vh", sm: "300px", md: "400px", lg: "500px" }}
            borderRadius={{ xs: 0, sm: 2 }}
            className="card"
            sx={sx}
            style={style}
        >
            {children}
        </Box>
    )
}

export function Card({
    title,
    content,
    backgroundVideoYoutubeCode,
    color,
    subTitle,
    subTitle2,
    subTitleAfterTitle,
    backgroundImage
}: CardType) {
    const [showContent, setShowContent] = useState(false)

    return (
        <Fragment>
            {backgroundVideoYoutubeCode &&
                <Box position="absolute" top={-2} bottom={0} right={0} left={-2} zIndex={showContent ? 1 : "unset"}>
                    <iframe title='ytplayer' id="ytplayer" itemType="text/html" width="100%" height="100%"
                        src={`https://www.youtube-nocookie.com/embed/${backgroundVideoYoutubeCode}?autoplay=1&mute=1&controls=0&disablekb=1&enablejsapi=1&fs=0&loop=1&playlist=${backgroundVideoYoutubeCode}&modestbranding=1&playsinline=1&iv_load_policy=3&showinfo=0`}
                    ></iframe>
                </Box>
            }
            {showContent &&
                <Box className="card" position="absolute" top={0} bottom={0} right={0} left={0}
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "100% 100%",
                        zIndex: 1
                    }}>
                    <CardIconButton
                        className="card-button fade"
                        sx={{ marginTop: "10px", right: "10px", zIndex: 100 }}
                        onClick={() => { setShowContent(v => !v); console.log("asd") }}>
                        <NotesIcon />
                    </CardIconButton>
                </Box>
            }
            <CardLayout sx={{
                backgroundColor: color || "transparent",
                color: "primary.contrastText",
                position: "absolute",
                display: "flex"
            }} style={{
                backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 70%), url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "100% 100%"
            }}>
                <CardIconButton
                    className="card-button fade"
                    sx={{ marginTop: "10px", right: "10px" }}
                    onClick={() => { setShowContent(true) }}>
                    <PanoramaIcon />
                </CardIconButton>
                {content}

            </CardLayout >
        </Fragment >
    )
}