
import * as React from 'react';
import { Box, Button } from '@mui/material';
import { CardGroup } from '../components/Card/CardGroup';
import fullMe from "./../images/home/fullMe.jpg"
import upfImg from "./../images/training/trainingBg.png"
import particularLessonsImg from "./../images/labExp/lessons.png"
import homeBg from "./../images/home/homeBg.jpg"
import trampoliImg from "./../images/labExp/trampoli.jpg"
import beabloo from "./../images/labExp/beabloo.gif"
import { CardType, createCard } from '../components/Card/Card';
import { useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TransitionsModal from '../components/Modal';
import salesmanSim from "./../images/examples/salesmanSim.png";

const pagesCards: {
    [key: string]: {
        title?: string,
        subtitle?: string,
        cards: CardType[]
    }
} = {
    "Main": {
        title: "About me",
        cards: [
            createCard(
                {
                    title: "Hello!",
                    content: (
                        < React.Fragment >
                            <p>
                                I am Martí Mayoral, a {new Date().getFullYear() - 1999}-year-old
                                graduate in Computer Science and this is my personal page. Welcome!</p>
                            <p>
                                I was born in Barcelona and I lived there most of my life.
                                When I turned 23 I started traveling while working.
                            </p>
                            <p>
                                I have worked as a full stack developer, using engines like Unity or PixiJs and frameworks like React.  
                            </p>
                            <p>
                                During 2023 I traveled mostly in Germany and Spain. I did volunteer work and stayed in hostels to help me finance it.
                            </p>

                            <form style={{ marginTop: "24px" }} action="CV_Public_Eng.pdf" target="_blank">
                                <Button color='secondary' variant='outlined' type="submit">
                                    Download my CV
                                    <OpenInNewIcon sx={{ marginLeft: "4px" }} fontSize='inherit' />
                                </Button>
                            </form>
                        </React.Fragment >
                    ),
                    backgroundImage: fullMe
                }
            )
        ]
    },
    "projects": {
        title: "Demo projects",
        cards: [
            createCard(
                {
                    title: "Virtual art gallery editor and viewer",
                    subTitle2: "React, PixiJs and Unity",
                    content: (
                        <React.Fragment>
                            <p>This has been my largest React project. It is the work i did for ITGallery.</p>
                            <p>More about it <a href="/laboral-experience?topic=Virtual+art+gallery+editor+and+viewer">here</a>.</p>
                            <p>Try it out!</p>
                        </React.Fragment>
                    ),
                    footer: (
                        <TransitionsModal
                            iframeSrc='/embeded/exhibifyVr/'
                            sx={{ width: { xs: "100%", sm: "90%" }, height: { xs: "100%", sm: "90%" } }}
                            buttonText={"Try it yourself!"} />
                    ),
                    backgroundVideoYoutubeCode: "2gi_gzwWdH8"
                }
            ),
            createCard(
                {
                    title: "Light it up",
                    subTitleAfterTitle: "JAN2022-2023",
                    content: (
                        <React.Fragment>
                            <p>Afer graduating from computer science I wanted to work on some projects on my own. Getting more experience in Unity.</p>
                            <p>During my bachelor, I did a C++ game and I enjoyed it, so I made it in Unity to learn more about the technology. </p>
                            <p>Its a simple puzzle game where you have to light up all the bulbs. I used firebase to set up level sharing.</p>
                            <p>It is currenty published for Android on Google Play Store, you can check it out on the link below.</p>
                        </React.Fragment>
                    ),
                    footer: (
                        <React.Fragment>

                            <TransitionsModal
                                content={
                                    <iframe
                                        style={{ aspectRatio: (31 / 25), width: "100%", border: 0 }}
                                        src="/embeded/games/liu/lightItUpJs.html"
                                        title="light it up"
                                    />
                                }
                                sx={{ width: { xs: "100%", sm: "70%", md: "50%" } }}
                                buttonSx={{ mr: 2 }}
                                buttonText={"Try Javascript demo version!"} />
                            {/* <Button
                                href='https://play.google.com/store/apps/details?id=com.mmayo.LightItUp'
                                variant='outlined' color="info">Google Play Page</Button> */}
                        </React.Fragment>
                    ),
                    backgroundVideoYoutubeCode: "-v4oWRMVQds"
                }
            ),
            createCard(
                {
                    title: "Salesman simulation",
                    subTitle2: "Typescript PixiJs",
                    content: (
                        <React.Fragment>
                            <p>This is a simple salesman problem solver to showcase my knowladge in PixiJs and Algorithms.</p>
                            <p>Try it out!</p>
                        </React.Fragment>
                    ),
                    footer: (
                        <TransitionsModal
                            iframeSrc='/embeded/salesman-simulation/'
                            sx={{ width: { xs: "100%", sm: "90%" }, height: { xs: "100%", sm: "90%" } }}
                            buttonText={"Try it yourself!"} />
                    ),
                    backgroundImage: salesmanSim
                }
            ),
        ]
    },
    "laboral-experience": {
        title: "Laboral experience",
        cards: [
            createCard(
                {
                    title: "Virtual art gallery editor and viewer",
                    subTitleAfterTitle: "JAN 2022 - Present",
                    subTitle2: "Technologies used: React, PixiJs and Unity",
                    content: (
                        <React.Fragment>
                            <p>I worked as a full stack developer for <a target='blank' href='https://www.linkedin.com/company/itgallery/'>ITGallery</a>.
                                I started this project almost from the ground up and I was the only developer working on it.</p>
                            <p>My job consisted on design, prototype, develop, test and document the project.</p>
                            <p>The project consists of 2 parts:</p>
                            <ul>
                                <li>
                                    <p>The gallery editor: Where you create the scene, putting walls, lights and artwork, among others.<br />
                                        This part is done with ReactJs and PixiJs.</p>
                                </li>
                                <li>
                                    <p>The gallery viewer: Where you visualize the scene in a 3D environment.<br />
                                        This part is done with Unity.</p>
                                </li>
                            </ul>
                            <p>See a demo <a href='projects'>here</a></p>
                        </React.Fragment>
                    ),
                    backgroundVideoYoutubeCode: "2gi_gzwWdH8"
                }
            ),
            createCard(
                {
                    title: "Digital Signage Software Developer",
                    subTitleAfterTitle: "OCT 2019 - JUL 2020",
                    subTitle2: "Level 2 IT Technical support",
                    content: (<React.Fragment>
                        <p>I started my work at the digital signage company <a target='blank' href='https://www.linkedin.com/company/beabloo/'>Beabloo</a> as
                            an intern for my Bachelor degree. After my internship ended, I was hired.</p>
                        <p>As a Level 2 IT Technical support professional, my role is to provide more advanced assistance and expertise to both end-users and Level 1 support staff.</p>
                        <p>We used a ticket manager system and Jira to manage the issues. Sometimes, I also had to do Quality Assurance if the developers were occupied.</p>
                    </React.Fragment>),
                    backgroundImage: beabloo
                }
            ),
            createCard(
                {
                    title: "University IT Support",
                    subTitleAfterTitle: "2018",
                    content: (
                        <React.Fragment>
                            <p>I worked as an IT support at my university, UPF.</p>
                            <p>I was in charge of helping students with the printing system, installing programs, scanning, etc. I also helped the professors with the computers, audio and projectors of their classes.</p>
                        </React.Fragment>
                    ),
                    backgroundImage: upfImg
                }
            ),
            createCard(
                {
                    title: "Particular lessons",
                    subTitleAfterTitle: "2019 - Present",
                    content: (
                        <React.Fragment>
                            <p>Since 2019, I started giving particular lessons to those who need it. The fields that I have taught the most are Microsoft Excel and maths.</p>
                            <p>I have used the platform Superprof, a website that connects tutors with students in need of extra training.</p>
                        </React.Fragment>
                    ),
                    backgroundImage: particularLessonsImg
                }
            ),
            createCard(
                {
                    title: "Trampoline Gymnastics Coach",
                    subTitleAfterTitle: "2015 - 2021",
                    content: (
                        <React.Fragment>
                            <p>After beeing a gymnast I was asked to work as a gymnastics coach. It has improved my communication skills, time management, and I have become more adaptable.</p>
                            <p>Every session was planned carefuly to imporve childrens skills and values.</p>
                        </React.Fragment>
                    ),
                    backgroundImage: trampoliImg
                }
            ),
        ]
    },
    "training": {
        title: "Where did I study?",
        cards: [
            createCard(
                {
                    title: "Bachelor in UPF",
                    content: (
                        <React.Fragment>
                            <p>For my bachelor's degree, I went to Pompeu Fabra University (UPF). The degree specializes in Computer Engineering and it is one of the four degrees of the UPF's Polytechnic School.</p>
                            <p>The project I'm most proud of is one that I did for Human interaction subject. It is a first person game where you controll a dog with your position in a canvas and you have to guide the seep outside. It aims to help people with difficulties to connect with the world, such as those with autism. It is the one playing in the background.</p>
                            <p><i>UPF is a public university based in Barcelona that is highly competitive in research and aims to transform education to respond to future challenges. It is the 15th best young university in the world</i> -  <a target='_blank' rel='noreferrer' href='https://www.upf.edu/en/web/universitat/presentacio-breu-de-la-upf'>Times Higher Education</a></p>
                        </React.Fragment>
                    ),
                    backgroundVideoYoutubeCode: "alnhMGPmye8",
                }
            )
        ]
    },
    "contact": {
        title: "Contact",
        cards: [
            createCard(
                {
                    title: "How can you reach me?",
                    content: (
                        <React.Fragment>
                            <p>The best way to contact me is through LinkedIn or via email at marti.mayoral2@gmail.com.</p>
                        </React.Fragment>
                    ),
                    footer: (
                        <React.Fragment>
                            <Button
                                href='https://www.linkedin.com/in/martimayoral/'
                                variant='contained' color="info" sx={{ mr: 2 }}
                                target="_blank" >LinkedIn</Button>
                            <Button
                                variant='outlined' color="inherit"
                                target="_top"
                                rel="noopener noreferrer"
                                href={`mailto:marti.mayoral2@gmail.com`} >marti.mayoral2@gmail.com</Button>
                        </React.Fragment>
                    ),
                    backgroundImage: homeBg
                }
            )
        ]
    },
}

export function MainPage() {
    const { pageId } = useParams()

    const page = pagesCards[pageId || "Main"]
    // console.log("Page Cards", page)

    if (!page)
        return <div></div>
    return <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}
    >
        <h1>{page.title}</h1>
        {/* <h2>{page.subtitle}</h2> */}
        <CardGroup
            cards={page.cards || []}
        />
    </Box>
}