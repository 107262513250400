import CircleIcon from '@mui/icons-material/Circle';
import { Box } from '@mui/material';

function Dot({ selected, onClick, title }: { selected?: boolean, onClick?: () => void, title?: string }) {
    return <CircleIcon
        className={selected ? 'stepper-dot selected' : 'stepper-dot not-selected'}
        onClick={onClick}
        titleAccess={title}
    />
}

export default function DotsMobileStepper({ steps, activeStep, onClick, titles }
    : { steps: number, activeStep: number, onClick?: (step: number) => void, titles?: (string | undefined)[] }) {

    return (
        <Box>
            {Array.from({ length: steps }, (_, i) =>
                < Dot
                    key={i}
                    selected={activeStep === i}
                    onClick={() => onClick && onClick(i)}
                    title={titles && titles[i]}
                />
            )}
        </Box>
    );
}