import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Button, ButtonBase, IconButton, SxProps, Theme, useTheme } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    p: 2,
    boxShadow: 24,
};

export default function TransitionsModal({
    buttonText,
    content,
    sx,
    buttonSx,
    iframeSrc
}: {
    buttonText: React.ReactNode,
    content?: React.ReactNode,
    sx?: SxProps<Theme> | undefined
    buttonSx?: SxProps<Theme> | undefined,
    iframeSrc?: string
}) {
    let [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = React.useState(searchParams.get("active") === "true");
    const [isFullScreen, setFullScreenProps] = React.useState<boolean>(searchParams.get("fullScreen") === "true");
    const theme = useTheme()

    const handleOpen = () => {
        setOpen(true)
        searchParams.set("active", "true")
        setSearchParams(searchParams)
    }
    const handleClose = () => {
        setOpen(false)
        searchParams.delete("active")
        setSearchParams(searchParams)
    }
    const handleFullScreenButton = () => {
        if (isFullScreen) {
            document.exitFullscreen()
            setFullScreenProps(false)
            searchParams.delete("fullScreen")
            setSearchParams(searchParams)
        } else {
            document.body.requestFullscreen();
            setFullScreenProps(true)
            searchParams.set("fullScreen", "true")
            setSearchParams(searchParams)
        }
    }

    return (
        <React.Fragment>
            <Button variant='outlined' size="large" onClick={handleOpen} sx={buttonSx}>{buttonText}</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={isFullScreen ? { ...style, ...sx, width: "100vw", height: "100vh" } : { ...style, ...sx }}>
                        {iframeSrc ?
                            <iframe
                                style={{ width: "100%", height: "100%", border: 0 }}
                                src={iframeSrc}
                                title="modal iframe"
                            />
                            :
                            content
                        }
                        <Box
                            sx={{ display: "flex", flexDirection: "column", position: "absolute", top: 0, right: { sm: isFullScreen ? 0 : -50, xs: 0 }, m: 2 }}
                        >
                            <ButtonBase
                                sx={{ borderRadius: 2, m: 1, p: 1, background: theme.palette.background.paper }}
                                onClick={handleClose}
                                color="secondary">
                                <CloseIcon />
                            </ButtonBase>
                            <ButtonBase
                                sx={{ borderRadius: 2, mx: 1, p: 1, background: theme.palette.background.paper }}
                                onClick={handleFullScreenButton}
                                color="secondary">
                                {
                                    isFullScreen ?
                                        <CloseFullscreenIcon />
                                        :
                                        <OpenInFullIcon />
                                }
                            </ButtonBase>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}