import { Routes, Route, Outlet, BrowserRouter } from "react-router-dom";
import { Header } from './components/Header';
import { MainPage } from './pages/MainPage';
import React from 'react'
import { Page } from './types/pages';

const pages: Page[] = [
  {
    title: "Home",
    path: "/"
  },
  {
    title: "Demo Projects",
    path: "/projects"
  },
  {
    title: "Laboral Experience",
    path: "/laboral-experience"
  },
  {
    title: "Training",
    path: "/training"
  },
  {
    title: "Contact",
    path: "/contact"
  }
]

function App(): JSX.Element {
  const apiRegex = /.*\/demo\/immfly.*/
  if (apiRegex.test(window.location.pathname)) {

    window.location.replace("/demo/immfly")
    return <div />
  }

  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<MainPage />} />
            <Route path="/:pageId" element={<MainPage />} />
          </Route>

          <Route path="/portfolio">
            <Route index element={<div>this is the portfolio redirector</div>} />
            {/* <Route path="/" element={<Editor />} /> */}

            {/* <Route path="authError" element={<AuthErrorPage />} /> */}

            {/* <Route index path="*" element={<UnknownPage />} /> */}
          </Route>

        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  )
}

export default App;

function Layout() {
  return (
    <div className="App">
      <Header pages={pages} />
      <Outlet />
    </div>
  )
}